
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    tag: {
      type: String,
      required: true,
      default: "---",
    },
  },
  methods: {
    removeTag: function (tag: string) {
      this.$emit("remove", tag);
    },
  },
})
export default class TagItem extends Vue {
  tag!: string;
}
