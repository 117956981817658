<template>
  <span class="tag-search-text" @click="removeTag(tag)">
    {{ tag }}
    <span class="tag-search-bg"></span>
    <span class="tag-search-x">x</span>
  </span>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    tag: {
      type: String,
      required: true,
      default: "---",
    },
  },
  methods: {
    removeTag: function (tag: string) {
      this.$emit("remove", tag);
    },
  },
})
export default class TagItem extends Vue {
  tag!: string;
}
</script>

<style scoped>
.tag-search-text {
  @apply relative bg-blue-300 h-6 px-4 py-2 md:py-1 mx-1 rounded-md whitespace-nowrap cursor-pointer;
}
.tag-search-x {
  @apply absolute text-sm top-0 right-0 -mt-2 -mr-0.5 text-blue-500 font-semibold;
}
.tag-search-bg {
  @apply absolute text-xs w-3 h-3 top-0 right-0 -mt-1 -mr-1 bg-gray-200 border-white border-2 rounded-full;
}
</style>
