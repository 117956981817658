<template>
  <div
    v-if="pagination && pagination.page"
    class="
      fixed
      z-10
      left-0
      right-0
      bottom-0
      w-screen
      pb-2
      border-white
      pt-2
      border-t-2
    "
    :class="[
      isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-gray-200 text-gray-900',
    ]"
  >
    <div class="flex flex-wrap justify-center w-96 mx-auto">
      <a
        @click="route('previous')"
        class="
          cursor-pointer
          select-none
          block
          px-3
          py-4
          mx-1
          border-gray-300 border-2
          hover:border-blue-300
        "
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.previous"
        >&#8592;
      </a>
      <a
        @click="route('first')"
        class="
          cursor-pointer
          select-none
          block
          px-3
          py-4
          mx-1
          border-gray-300 border-2
          hover:border-blue-300
        "
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.first"
        v-html="pagination.page.first"
      >
      </a>
      <div
        class="block px-2 py-4 mx-1"
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.current - 1 > 1"
      >
        ...
      </div>
      <a
        class="
          cursor-default
          select-none
          block
          px-2
          py-4
          mx-1
          border-gray-400 border-2
        "
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.current"
        v-html="pagination.page.current"
      >
      </a>
      <div
        class="block px-2 py-4 mx-1"
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.last - pagination.page.current > 1"
      >
        ...
      </div>
      <a
        @click="route('last')"
        class="
          cursor-pointer
          select-none
          block
          px-3
          py-4
          mx-1
          border-gray-300 border-2
          hover:border-blue-300
        "
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.last"
        v-html="pagination.page.last"
      >
      </a>
      <a
        @click="route('next')"
        class="
          cursor-pointer
          select-none
          block
          px-3
          py-4
          mx-1
          border-gray-300 border-2
          hover:border-blue-300
        "
        :class="[
          isDarkMode
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]"
        v-if="pagination.page.next"
        >&#8594;
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Pagination from "@/types/Pagination";

@Options({
  props: {
    pagination: {},
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
})
export default class PaginationComponent extends Vue {
  pagination!: Pagination;

  route(type: string): void {
    if (!this.pagination || !this.pagination.page) {
      return;
    }
    switch (type) {
      case "first":
        if (!this.pagination.page.first) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.first },
        });
        this.$emit("updated", this.pagination.page.first);
        return;
      case "last":
        if (!this.pagination.page.last) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.last },
        });
        this.$emit("updated", this.pagination.page.last);
        return;
      case "next":
        if (!this.pagination.page.next) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.next },
        });
        this.$emit("updated", this.pagination.page.next);
        return;
      case "previous":
        if (!this.pagination.page.previous) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.previous },
        });
        this.$emit("updated", this.pagination.page.previous);
        return;
      case "current":
        if (!this.pagination.page.current) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.current },
        });
        this.$emit("updated", this.pagination.page.current);
        return;
    }
  }
}
</script>
