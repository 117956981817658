
import { Options, Vue } from "vue-class-component";
import Pagination from "@/types/Pagination";

@Options({
  props: {
    pagination: {},
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
})
export default class PaginationComponent extends Vue {
  pagination!: Pagination;

  route(type: string): void {
    if (!this.pagination || !this.pagination.page) {
      return;
    }
    switch (type) {
      case "first":
        if (!this.pagination.page.first) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.first },
        });
        this.$emit("updated", this.pagination.page.first);
        return;
      case "last":
        if (!this.pagination.page.last) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.last },
        });
        this.$emit("updated", this.pagination.page.last);
        return;
      case "next":
        if (!this.pagination.page.next) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.next },
        });
        this.$emit("updated", this.pagination.page.next);
        return;
      case "previous":
        if (!this.pagination.page.previous) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.previous },
        });
        this.$emit("updated", this.pagination.page.previous);
        return;
      case "current":
        if (!this.pagination.page.current) {
          return;
        }
        this.$router.push({
          name: "Articles",
          query: { page: this.pagination.page.current },
        });
        this.$emit("updated", this.pagination.page.current);
        return;
    }
  }
}
