import http from "@/services/http-common";
import qs from "qs";

class SearchService {
  searchAvailableTags(tag: string): Promise<any> {
    return http.get("/search", {
      params: { tag: tag },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  }
}

export default new SearchService();
