
import { defineComponent } from "vue";
import ArticleService from "@/services/ArticleService";
import Article from "@/types/Article";
import ResponseData from "@/types/ResponseData";
import Pagination from "@/types/Pagination";
import PaginationComponent from "@/components/PaginationComponent.vue";
import TagItem from "@/components/TagItem.vue";
import SearchService from "@/services/SearchService";

export default defineComponent({
  name: "articles-list",
  components: {
    TagItem,
    PaginationComponent,
  },
  data() {
    return {
      pageNumber: 1 as number,
      articles: [] as Article[],
      pagination: {} as Pagination,
      tags: [] as string[],
      searchedTags: [] as string[],
      searchText: "" as string,
    };
  },
  methods: {
    articleUrl(slug: string) {
      return "/articles/" + slug;
    },
    dateOnly(dateString: string) {
      const date = new Date(dateString);
      return (
        date.getDate() +
        " / " +
        (date.getMonth() + 1) +
        " / " +
        date.getFullYear()
      );
    },
    summary(text: string) {
      const desiredMinLength = 600;
      if (!text) {
        return "";
      }
      const cleanTest = text
        // Remove the main image and the figure caption around it
        .replace(/<figure[^>]*>(((?!figure).|[\n\r])*)<\/figure>/gim, "")
        // Remove LinkedIn link on the monthly posts
        .replace(
          /Click\s+<a[^>]+>[^<]+<\/a>\s+to\s+see\s+this\s+post\s+on\s+LinkedIn\./gi,
          ""
        )
        // Remove HTML tags
        .replace(/<\/?[^>]+(>|$)/g, "")
        // Keep only the first 1500 characters
        .substring(0, 1500);
      for (const wordNumber of Array.from({ length: 50 }, (_, i) => i * 10)) {
        if (
          cleanTest.split(" ").slice(0, wordNumber).join(" ").length >
          desiredMinLength
        ) {
          return cleanTest.split(" ").slice(0, wordNumber).join(" ") + " ...";
        }
      }
      return cleanTest.split(" ").slice(0, 300).join(" ");
    },
    retrieve(pageNumber: number, tags?: string[]) {
      ArticleService.getAll(pageNumber, tags)
        .then((response: ResponseData) => {
          this.articles = response.data.data;
          this.pagination = response.data.pagination;
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    searchAvailableTags(tag: string) {
      if (!tag) {
        this.searchedTags = [];
        return;
      }
      SearchService.searchAvailableTags(tag)
        .then((response: ResponseData) => {
          this.searchedTags = response.data.data.filter((tag: string) => {
            return !this.tags.includes(tag);
          });
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    getPageNumber(): number {
      let page = this.$route.query.page;
      if (typeof page === "string") {
        return parseInt(page);
      }
      return 1;
    },
    removeTag(tag: string) {
      this.tags = this.tags.filter((existingTag) => existingTag !== tag);

      this.$router.push({
        name: "Articles",
        query: {
          page: this.$route.query.page ? this.pageNumber : undefined,
          tags: this.tags,
        },
      });

      this.retrieve(this.pageNumber, this.tags);
    },
    addTag(tag: string) {
      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
      this.$router.push({
        name: "Articles",
        query: {
          page: this.$route.query.page ? this.pageNumber : undefined,
          tags: this.tags,
        },
      });

      this.searchText = "";
      this.searchedTags = [];
      this.retrieve(this.pageNumber, this.tags);
    },
  },
  mounted() {
    this.pageNumber = this.getPageNumber();
    switch (typeof this.$route.query.tags) {
      case "undefined":
        this.tags = [];
        break;
      case "string":
        this.tags = [this.$route.query.tags as string];
        break;
      default:
        this.tags = this.$route.query.tags as string[];
    }
    this.retrieve(this.pageNumber, this.tags);
  },
});
