<template>
  <h2 class="text-2xl font-bold">Articles</h2>
  <div class="mb-2 border-t-2 border-white pr-1 hidden md:block">
    <span
      class="
        text-sm
        font-bold
        float-right
        text-gray-500
        dark:text-gray-300
        pt-4
        lg:pt-1
      "
    >
      medium.com:
      <a
        target="_blank"
        class="hover:text-gray-600 dark:hover:text-gray-400"
        href="https://medium.com/@pooyan_razian"
      >
        @pooyan_razian
      </a>
    </span>
  </div>

  <div class="flex flex-wrap justify-center md:justify-start">
    <div
      class="
        input-group
        relative
        flex flex-shrink-0
        items-stretch
        w-full
        md:w-56
        mb-4
        rounded
        md:mr-3
      "
    >
      <input
        type="search"
        class="
          form-control
          relative
          flex-auto
          min-w-0
          block
          w-full
          pl-3
          pr-8
          py-2
          text-xs
          font-normal
          text-gray-700
          bg-white bg-clip-padding
          border border-solid border-gray-300
          rounded
          transition
          ease-in-out
          m-0
          focus:text-gray-700
          focus:bg-white
          focus:border-gray-400
          focus:outline-none
        "
        placeholder="Search by tag..."
        v-model="searchText"
        @input="searchAvailableTags(searchText)"
      />
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="search"
        class="w-9 absolute right-0 py-2 px-2 text-gray-300"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
        ></path>
      </svg>
    </div>
    <div class="absolute z-20 mt-9 px-10 md:px-0 md:mt-8 w-full">
      <div
        v-for="(searchedTag, index) in searchedTags"
        :key="index"
        @click="addTag(searchedTag)"
        class="
          flex flex-shrink-0
          w-full
          z-20
          py-4
          px-12
          md:w-56 md:py-2 md:pl-2
          border-b border-gray-100
          bg-gray-400
          text-gray-900
          font-semibold
          text-xs
          cursor-pointer
          hover:bg-blue-500
        "
      >
        {{ searchedTag }}
      </div>
    </div>
    <div
      class="
        block
        relative
        z-10
        md:flex md:flex-grow-0
        flex-wrap
        py-1
        px-0
        text-gray-900
        font-semibold
        text-xs
        leading-10
        md:leading-snug md:gap-x-1 md:gap-y-2
      "
    >
      <tag-item
        v-for="(tag, index) in tags"
        :key="index"
        :tag="tag"
        @remove="removeTag"
      >
      </tag-item>
    </div>
  </div>

  <div class="leading-8 md:leading-6 text-justify mb-6 font-nunito-sans">
    <div v-for="(article, index) in articles" :key="index" class="pb-6">
      <router-link :to="'/articles/' + article.slug">
        <div class="flex w-full border-b-2 border-color-gray-400">
          <div class="flex-auto text-left font-bold">{{ article.title }}</div>
          <div
            class="hidden md:inline flex-auto text-right text-sm font-semibold"
          >
            {{ dateOnly(article.publishedAt) }}
          </div>
        </div>
        <div class="pt-4 flex space-x-8 w-full">
          <div
            class="w-7/12 flex-grow text-gray-700 dark:text-gray-400"
            v-html="summary(article.content)"
          ></div>
          <div
            v-if="article.mainImage"
            class="hidden xl:inline-flex w-3/12 flex-shrink-0"
          >
            <img
              :src="article.mainImage"
              class="w-full rounded-l-md opacity-90"
            />
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <pagination-component
    :pagination="pagination"
    @updated="retrieve"
  ></pagination-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ArticleService from "@/services/ArticleService";
import Article from "@/types/Article";
import ResponseData from "@/types/ResponseData";
import Pagination from "@/types/Pagination";
import PaginationComponent from "@/components/PaginationComponent.vue";
import TagItem from "@/components/TagItem.vue";
import SearchService from "@/services/SearchService";

export default defineComponent({
  name: "articles-list",
  components: {
    TagItem,
    PaginationComponent,
  },
  data() {
    return {
      pageNumber: 1 as number,
      articles: [] as Article[],
      pagination: {} as Pagination,
      tags: [] as string[],
      searchedTags: [] as string[],
      searchText: "" as string,
    };
  },
  methods: {
    articleUrl(slug: string) {
      return "/articles/" + slug;
    },
    dateOnly(dateString: string) {
      const date = new Date(dateString);
      return (
        date.getDate() +
        " / " +
        (date.getMonth() + 1) +
        " / " +
        date.getFullYear()
      );
    },
    summary(text: string) {
      const desiredMinLength = 600;
      if (!text) {
        return "";
      }
      const cleanTest = text
        // Remove the main image and the figure caption around it
        .replace(/<figure[^>]*>(((?!figure).|[\n\r])*)<\/figure>/gim, "")
        // Remove LinkedIn link on the monthly posts
        .replace(
          /Click\s+<a[^>]+>[^<]+<\/a>\s+to\s+see\s+this\s+post\s+on\s+LinkedIn\./gi,
          ""
        )
        // Remove HTML tags
        .replace(/<\/?[^>]+(>|$)/g, "")
        // Keep only the first 1500 characters
        .substring(0, 1500);
      for (const wordNumber of Array.from({ length: 50 }, (_, i) => i * 10)) {
        if (
          cleanTest.split(" ").slice(0, wordNumber).join(" ").length >
          desiredMinLength
        ) {
          return cleanTest.split(" ").slice(0, wordNumber).join(" ") + " ...";
        }
      }
      return cleanTest.split(" ").slice(0, 300).join(" ");
    },
    retrieve(pageNumber: number, tags?: string[]) {
      ArticleService.getAll(pageNumber, tags)
        .then((response: ResponseData) => {
          this.articles = response.data.data;
          this.pagination = response.data.pagination;
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    searchAvailableTags(tag: string) {
      if (!tag) {
        this.searchedTags = [];
        return;
      }
      SearchService.searchAvailableTags(tag)
        .then((response: ResponseData) => {
          this.searchedTags = response.data.data.filter((tag: string) => {
            return !this.tags.includes(tag);
          });
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    getPageNumber(): number {
      let page = this.$route.query.page;
      if (typeof page === "string") {
        return parseInt(page);
      }
      return 1;
    },
    removeTag(tag: string) {
      this.tags = this.tags.filter((existingTag) => existingTag !== tag);

      this.$router.push({
        name: "Articles",
        query: {
          page: this.$route.query.page ? this.pageNumber : undefined,
          tags: this.tags,
        },
      });

      this.retrieve(this.pageNumber, this.tags);
    },
    addTag(tag: string) {
      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
      this.$router.push({
        name: "Articles",
        query: {
          page: this.$route.query.page ? this.pageNumber : undefined,
          tags: this.tags,
        },
      });

      this.searchText = "";
      this.searchedTags = [];
      this.retrieve(this.pageNumber, this.tags);
    },
  },
  mounted() {
    this.pageNumber = this.getPageNumber();
    switch (typeof this.$route.query.tags) {
      case "undefined":
        this.tags = [];
        break;
      case "string":
        this.tags = [this.$route.query.tags as string];
        break;
      default:
        this.tags = this.$route.query.tags as string[];
    }
    this.retrieve(this.pageNumber, this.tags);
  },
});
</script>
